.Auth {
    display: block;
    .LoginForm {
      width: 100%;
      background: #f5f5f5;
      position: fixed;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      color: #474747;
  
      margin: 0 auto;
      padding: 0;
      display: flex;
      .left {
        width: 50%;
        margin: auto;
        text-align: center;
      }
  
      .right {
        width: 50%;
        background-color: #ffffff;
  
        .container {
          margin: 20%;
        }
      }
    }
  
    .footer {
      width: 100%;
      position: fixed;
      bottom: 0;
      display: block;
      background-color: #384285;
      padding: 1.5em;
      color: #eeeeee;
    }
  
    .field {
      margin-bottom: 1em;
      width: 100%;
      .label {
        font-weight: bold;
        margin-bottom: 4px;
        display: block;
      }
      .input {
        width: 100%;
        padding: 8px 11px;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
      }
    }
  
    .link {
      color: #1e9be4;
      font-weight: bold;
      text-align: right;
      cursor: pointer;
    }
  
    .error-message {
      color: #cf1b1b;
      margin: 1em;
    }
  
    .btn {
      color: #ffffff;
      background-color: #1e9be4;
      font-size: 14px;
      font-weight: bold;
      padding: 9px;
      text-align: center;
      border-radius: 12px;
      border: 1px solid #1e9be4;
      width: 100%;
      margin-top: 2em;
    }
  
    img {
      width: 80%;
    }
  
    // loading animation
    .loading {
      text-align: center;
    }
    .lds-ellipsis {
      display: inline-block;
      position: relative;
      width: 80px;
      height: 80px;
    }
    .lds-ellipsis div {
      position: absolute;
      top: 33px;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background: rgb(168, 168, 168);
      animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }
    .lds-ellipsis div:nth-child(1) {
      left: 8px;
      animation: lds-ellipsis1 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(2) {
      left: 8px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(3) {
      left: 32px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(4) {
      left: 56px;
      animation: lds-ellipsis3 0.6s infinite;
    }
    @keyframes lds-ellipsis1 {
      0% {
        transform: scale(0);
      }
      100% {
        transform: scale(1);
      }
    }
    @keyframes lds-ellipsis3 {
      0% {
        transform: scale(1);
      }
      100% {
        transform: scale(0);
      }
    }
    @keyframes lds-ellipsis2 {
      0% {
        transform: translate(0, 0);
      }
      100% {
        transform: translate(24px, 0);
      }
    }
  }
  