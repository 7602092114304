.message-list {
  width: 100%;
  height: 50vh;
  overflow: auto;
  background-color: #e4e7e6;
}
.message-list .rce-mlist {
  padding: 10px 30px;
}
.rce-container-mbox{
  padding: 0px 33px;
}
/* .input-form{
    width: 470px;
    background-color:white;
    border-radius: 100px;
}
.input-form::before{
    border-bottom: none !important;
    padding: 0px 20px !important;
}
.input-form::after{
    border-bottom: none !important;
    padding: 0px 20px !important;
} */
/* input{
    padding: 6px 20px 7px !important;
    font-size: 13.6px !important;
} */

.attech {
  color: #a5a5a8;
}
.attech:hover {
  color: #fff;
}
.btn {
  color: #a5a5a8;
}
.btn:hover {
  color: #fff;
}

.rce-mbox {
  margin: 1rem;
  max-width: 60%;
}
.rce-mbox-photo img {
  width: 100%;
}
.rce-mbox-photo {
  cursor: pointer;
}
