.form-control {
  min-width: 20vw !important;
  max-width: 20vw !important;
  margin-bottom: 20px !important;
  display: flex !important;
}

.report-action {
  display: flex;
  justify-content: flex-start;
}

.Report {
  table {
    margin-top: 2rem;
    font-size: smaller;
    &::before {
      border: 1px solid black;
      display: block;
    }
  }

  table,
  td,
  th {
    border: 1px solid #333;
    border-collapse: collapse;
    text-align: left;
  }

  td,
  th {
    padding: 5px;
  }

  thead {
    font-weight: bold;
    background-color: #ddd;
  }
}
